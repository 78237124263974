@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@900&display=swap");
body {
  font-family: Rubik, sans-serif;
  color: #333;
  font-size: 1vw;
  line-height: 1.4;
  font-weight: 400;
  background-color: #031733;
  background-image: url("../images/blue_bg.png");
  background-position: 0% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.timer-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: #fff;
}

.timer-head{
  font-size: 48px;
  color: #fff;
  text-align: center;
      margin: 2rem 0 0 0;
}
.timer-content{
  position: relative;

}
.timer-content div{
font-size: 14px;
position: absolute;
top:-2.5em;
left: 0;
width: 100%
}
.div-block-11 {
  text-align: center;
}
section.faq-title h2 {
  margin-bottom: 1.78rem;
}
div.hero {
  width: 100%;
  /* height: 500px; */
  min-height: auto;
  position: relative;
}
.hero::after {
  display: none;
}
div.hero video {
  object-fit: contain;
  width: 100%;
  height: auto;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
}
div.hero div.caption {
  display: none;
}
.video-wrapper {
  width: 100%;
  margin: 2rem 0;
}
.video-wrapper video {
  width: 80%;
  margin: 1rem auto;
  display: flex;
}
div.hero div.caption.mobile {
  display: flex;
  flex-direction: column;
  margin: 0 0 1rem 0;
  transform: translate(100vw, 0px);
}
.caption h2 {
  text-align: center;
}
/* .arrow{
  width:70px;
  height: 70px;
  border-radius: 50%;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  margin: 0 0 2rem 1rem;
  left: 0;
  color: #fff;
  fill: #fff;
  transform: rotate(180deg)
} */
.nav-desktop {
  display: none;
}
div.nav-text svg {
  display: flex;
  margin: 0 auto 0.5rem auto;
}
.mobile-burger {
  margin: 0 0.8rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  z-index: 1000;
  -webkit-tap-highlight-color: transparent;
}
.mobile-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background: #fff;
  width: 80vw;
  height: 100vh;
  text-align: center;
  position: fixed;
  top: 0;
  z-index: 5;
  left: 0;
  transition: 0.3s;
  list-style: none;
  transform: translateX(100vw);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.creators-grid.splide__list {
  grid-column-gap: 2em;
  grid-row-gap: 2em;
}

.disclaimer {
  margin: 0 0 0 0.5rem;
  color: #fff;
  font-weight: 200;
}
a {
  color: #fff;
}
.creators-wrapper {
  width: 90%;
  margin: 0 auto;
}
.first-section {
  display: flex;
}
.second-section {
  display: flex;
}
img.home-img {
  height: 600px;
  object-fit: contain !important;
  /* transform: translateY(200px); */
}
.feature {
  display: flex;
  margin: 0 auto;
  width: 90%;
  align-items: center;
}
.roadmap-wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.dark-img {
  background-image: url("../images/dark_sky.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
}
.spaceShip-bg {
  background-image: url("../images/starwars.png");
  background-attachment: fixed;
  background-size: 640px 336px;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  margin-top: 10rem;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.dark-overlay {
  background-image: linear-gradient(45deg, rgb(0, 0, 0, 0.8), transparent);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.space-font {
  font-family: "Orbitron", sans-serif !important;
  text-transform: uppercase !important;
  font-weight: 900 !important;
}
.faq-title {
  justify-content: center !important;
  text-align: center !important;
}
.other-artists {
  display: grid;
  width: 100%;
  row-gap: 1rem;
  column-gap: 1rem;
  grid-auto-columns: 1fr;
}
.lead-artist {
  margin: 0 0 2rem 0;
}
.lead-artist-txt {
  width: 50%;
}
.creator-info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(100vw, 0);
}
header {
  /* margin-left: 2rem; */
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.footer {
  display: flex;
  margin: 2rem auto 0 auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  /* transform: translate(0, 100vw); */
  left: 0;
  bottom: 0;
  position: relative;
}

.footer-mobile {
  display: none;
}
.footer-divider {
  margin: 4rem 0 0 0;
  height: 3px;
  width: 100%;
  color: #fff;
  background-color: #fff;
}
.creator-social {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  margin-top: 1rem;
  color: #fff;
  /* transform: translate(100vw, 0); */
}
.creator-social svg {
  margin: 0 1.5rem;
}
.image-wrapper-1 {
  width: 40vw;
  position: relative;
  height: 300px;
  margin: 0 0 0 1rem;
}
.image-wrapper-2 {
  /* border: 3px solid #fff; */
  width: 100vw;
  position: relative;
  height: 300px;
}
.image-wrapper-2 div {
  left: 0;
  right: 100%;
  width: 100vw;
}
.image-wrapper-2 div img {
  position: absolute;
  object-fit: contain;
  width: auto;
  right: 0;
  height: 100%;
  padding: 1rem 4rem 1rem 0rem;
}
.image-wrapper-1 div {
  left: 100%;
  right: 0;
  width: 100vw;
}
.image-wrapper-1 div img {
  position: absolute;
  object-fit: contain;
  width: auto;
  height: 100%;
  padding: 1rem 0 1rem 4rem;
}
.section {
  position: relative;
  z-index: 1;
}

.paragraph-wrapper {
  width: 50%;
  text-align: justify;
  /* margin: 1rem 0; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.paragraph.paragraph-heading {
  width: 100%;
  margin: 2rem 0;
}
.section.no-overflow {
  overflow: hidden;
}

.section.atf {
  min-height: 100%;
}

.container {
  width: 100%;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  position: relative;
  padding: 5em 0 0 0;
  margin-right: auto;
  margin-left: auto;
}

.container.atf-home {
  padding-top: 5em;
}

.container.btf {
  flex-direction: column;
  align-items: center;
}

.container.roadmap-container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.container.timer-container {
  padding-top: 10.05em;
  padding-bottom: 17.25em;
}

.container.btf-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  padding-top: 10.13em;
  padding-bottom: 13.28em;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.container.sneakpeak {
  padding-bottom: 2rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.logo-block {
  /* background-color: #fff; */
  /* margin: 0 auto; */
  position: absolute;
  left: 50%;
  top: 1.5em;
  transform: translate(-50%, 0%);
  width: 9em;
  height: 9em;
}
/* .logo-block-m{
  position: absolute; 
  left: 50%;
  top: 50%;
  transform: translate(0px, 50%);
    width: 9em;
  height: 9em;
} */
.logo-block-footer {
  position: relative;
  left: 0;
  top: -1.5rem;
  width: 12em;
  height: 12em;
  margin: 0;
}
.image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.image.contain {
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 100% 50%;
  object-position: 100% 50%;
}

.image.auto-height {
  height: auto;
}

.image.offset-up {
  position: relative;
  top: -13.5%;
}

.navbar {
  margin: 0 1rem 0 auto;
  z-index: 99999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-link {
  margin-left: 3.31em;
  font-family: "Orbitron", sans-serif;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.nav-link.first {
  margin-left: 0em;
}

.nav-text {
  font-size: 0.8em;
  color: #fff;
}

.button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 11.57em;
  height: 3.24em;
  margin-left: 3.97em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.66em;
  background-color: #265133;
  font-family: Rubik, sans-serif;
  color: #fff;
  font-size: 0.93em;
  font-weight: 500;
  text-decoration: none;
}

.atf-header-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.heading {
  position: static;
  font-family: "Orbitron", sans-serif;
  color: #fff;
  font-size: 7.28em;
  /* white-space: nowrap; */
  line-height: 1.1;
  font-weight: 600;
}

.caps-text-light {
  font-family: Lemonmilk, sans-serif;
  color: #fff;
  font-size: 1.06em;
  font-weight: 300;
  text-transform: uppercase;
}

.caps-text-bold {
  font-family: Lemonmilk, sans-serif;
  color: #fff;
  font-size: 1.59em;
  font-weight: 700;
}

.button-contain-atf {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.button-bigger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 1.32em 2.65em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.66em;
  background-color: #265133;
  font-family: Lemonmilk, sans-serif;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
}
.button-bigger:hover {
  transform: scale(0.95);
  opacity: 0.9;
}
.button-bigger.white {
  background-color: #fff;
  color: #265133;
}

.button-bigger.white.mobile-only {
  display: none;
}

.button-bigger.mobile-button {
  margin-top: 3em;
  cursor: pointer;
}

.button-bigger.left-margin {
  margin-left: 1.39em;
}

.button-bigger.cursor-normal {
  cursor: pointer;
}

.button-text {
  font-size: 1.32em;
}

.side-panel {
  transition: opacity 0.5s;
  z-index: 999999;
  position: fixed;
  left: -14em;
  top: 0%;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.side-panel-link-text {
  color: #fff;
  font-size: 1.2em;
  text-decoration: none;
  text-transform: uppercase;
}

.side-panel-link {
  margin-right: 3.17em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  text-decoration: none;
}

.side-panel-link.no-margin-right {
  overflow: hidden;
  margin-right: 0em;
  font-family: "Rubik", sans-serif;
  font-weight: 300;
}

.side-panel-wrap {
  display: none;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  padding: 1.5rem 2rem;
  border: 2px solid #fff;
}

.success-heading-block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2.78em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.h2-hollow {
  font-size: 2em;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Orbitron", sans-serif;
  color: #f2f2f2;
  line-height: 1.3555555555555556;
}

.h2-hollow.bit-smaller {
  font-size: 1.98em;
}

.h2-full {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Orbitron", sans-serif;
  text-transform: uppercase;
  color: #f2f2f2;
  font-size: 6.62em;
  line-height: 1;
  text-shadow: 0 0 20px hsla(0, 0%, 100%, 0.25);
}

.h2-full.offset {
  margin-top: -0.23em;
}

.paragraph {
  margin-bottom: 0px;
  color: #fff;
  font-size: 1.56em;
  line-height: 1.1818181818181819;
}

.paragraph.copyright {
  margin-left: 1em;
}

.paragraph-wrap-success {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2.5em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.h2-hollow-smaller {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Orbitron", sans-serif;
  color: #f2f2f2;
  font-size: 1.98em;
  line-height: 1.3555555555555556;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.roadmap-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2.78em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.roadmap-content-block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10.19em;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.roadmap-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 20vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.rodmap-paragraphs-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 45.9em;
  margin-left: 6.3em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.roadmap-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30em;
  margin-top: 3.97em;
  margin-bottom: 3.97em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.roadmap-content._1 {
  text-align: left;
}

.roadmap-sticky-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.number-tracker {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.changing-numbers-block {
  position: relative;
  width: 2em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.changing-numbers {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
  font-family: Lemonmilk, sans-serif;
  color: #fff;
  font-size: 1.32em;
  font-weight: 500;
}

.changing-numbers.is--active {
  opacity: 1;
}

.banana-bar {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 1.79em;
  margin-left: 1.06em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.end-points {
  width: 1.65em;
  height: 1.65em;
  border-radius: 100%;
  background-color: #fff;
}

.bar {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 0.2em;
  height: 32.74em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    color-stop(41%, hsla(0, 0%, 100%, 0.67)),
    to(hsla(0, 0%, 100%, 0.38))
  );
  background-image: linear-gradient(
    180deg,
    #fff,
    hsla(0, 0%, 100%, 0.67) 41%,
    hsla(0, 0%, 100%, 0.38)
  );
}

.changing-words-block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20.77em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.h3-roadmap {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Orbitron", sans-serif;
  text-transform: uppercase;
  color: #fff;
  font-size: 2.51em;
  line-height: 1.3421052631578947;
}

.h3-roadmap.absolute {
  position: absolute;
  opacity: 0;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}

.h3-roadmap.absolute.is--actived {
  opacity: 1;
}

.div-block-4 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.heading-2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Lemonmilk, sans-serif;
  color: #fff;
  font-size: 5.62em;
  line-height: 1.3529411764705883;
  text-shadow: 0 0 20px hsla(0, 0%, 100%, 0.25);
}

.hollow.no-shadow {
  text-shadow: 0 0 0 transparent;
}

.div-block-5 {
  margin-top: 1.79em;
  margin-bottom: 2.31em;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.banana-scroll {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 2.98em;
  height: 0px;
  margin-top: -0.5em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.banana-image {
  width: 3.98em;
}

.div-block-7 {
  width: 1px;
  height: 1px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.div-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 7.14em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.thin-caps {
  font-family: Lemonmilk, sans-serif;
  color: #fff;
  font-size: 1.06em;
  font-weight: 300;
}

.thick-caps {
  font-family: Lemonmilk, sans-serif;
  color: #fff;
  font-size: 1.59em;
  font-weight: 700;
}

.div-block-9 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.eyes-offset {
  position: absolute;
  left: auto;
  top: 0%;
  right: -18%;
  bottom: auto;
  margin-top: -0.55em;
  font-size: 1.59em;
}

.div-block-10 {
  width: 90%;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  margin-bottom: 5.35em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.h2-smaller {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Orbitron", sans-serif;
  color: #f2f2f2;
  font-size: 4.63em;
  line-height: 1;
  text-shadow: 0 0 20px hsla(0, 0%, 100%, 0.25);
}

.div-block-11 {
  max-width: none;
  width: 100%;
  margin-top: 1rem;
}

.creators-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
}

.picture-frame {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.66em;
}

.creator-photo {
  position: relative;
  z-index: 1;
  overflow: hidden;
  /* width: 22.69em; */
  /* height: 22.88em; */
  width: 20em;
  height: 20em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 3px;
  border: solid 3px #5d8ccc;
  padding: 1rem;
  transform: translate(100vw, 0);
}
div.creator-photo.reverse {
  transform: translate(-100vw, 0);
}
.creator-photo.add-height {
  width: 30.75em;
  height: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.creator-photo.sneak {
  width: 11.11em;
  height: 11.24em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.creator-photo.sneak-equals {
  width: 15.54em;
  height: 15.81em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.name-block {
  margin-top: 0.99em;
  margin-bottom: 1.12em;
}

.h3-name {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Orbitron", sans-serif;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.98em;
  line-height: 1.3506666666666667;
  text-align: center;
}

.creator-description {
  color: #fff;
  font-size: 1.46em;
  line-height: 1.1818181818181819;
  /* font-style: italic; */
  text-align: center;
}

.div-block-12 {
  padding-right: 0.5em;
  padding-left: 0.5em;
}

.infected-left-side {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 37.37em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.div-block-13 {
  margin-bottom: 3.7em;
}

.div-block-14 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.image-block-1 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 26.11em;
  height: 26.11em;
  border-radius: 0.66em;
  -webkit-transform: rotate(-22.42deg);
  -ms-transform: rotate(-22.42deg);
  transform: rotate(-22.42deg);
}

.image-block-2 {
  overflow: hidden;
  width: 23.3em;
  height: 23.26em;
  margin-left: -2.4em;
  border-radius: 0.66em;
  -webkit-transform: rotate(21.77deg);
  -ms-transform: rotate(21.77deg);
  transform: rotate(21.77deg);
}

.image-block-3 {
  position: relative;
  overflow: hidden;
  width: 23.84em;
  height: 23.89em;
  margin-top: -10.2em;
  border-radius: 0.66em;
  -webkit-transform: rotate(11.76deg);
  -ms-transform: rotate(11.76deg);
  transform: rotate(11.76deg);
}

.text-block-2 {
  font-family: Lemonmilk, sans-serif;
  color: #fff;
  font-size: 1.32em;
  font-weight: 700;
}

.faq-wrapper {
  width: 90%;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.faq-divider {
  width: 100%;
  height: 1px;
  margin-top: 1.52em;
  background-color: #fff;
}

.divider {
  width: 100%;
  height: 1px;
  margin-bottom: 2.58em;
  background-color: #fff;
}

.collapsible {
  overflow: hidden;
}

.div-block-15 {
  padding-top: 1.72em;
}

.socials-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.social-link {
  width: 3.57em;
  height: 3.57em;
  margin-right: 1.85em;
  padding: 0.4em;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 100%;
}

.mobile-appear {
  display: none;
  margin-bottom: 2em;
}

.mobile-menu-link {
  margin-bottom: 1em;
  font-family: Lemonmilk, sans-serif;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}

.mobile-menu-text {
  font-size: 3em;
}

.mobile-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
  transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.about-artist-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.div-block-18 {
  position: absolute;
  left: auto;
  top: auto;
  right: 1.7em;
  bottom: -2.6em;
  width: 25em;
}

.div-block-19 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.div-block-20 {
  position: relative;
  width: 10.32em;
}

.div-block-21 {
  position: relative;
  z-index: 1;
}

.div-block-19-a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 4.5em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.div-block-20-a {
  position: absolute;
  left: auto;
  top: -6em;
  right: 0%;
  width: 10.32em;
}

.about-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 6.35em;
}

.div-block-22 {
  max-width: 36.18em;
  margin-top: 2.5em;
}

.div-block-23 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1.65em;
}

.div-block-24 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-3 {
  font-family: Lemonmilk, sans-serif;
  color: #fff;
  font-size: 5.95em;
  font-weight: 700;
}

.div-block-25 {
  margin-right: 2em;
  margin-left: 2em;
}

.div-block-25.a {
  margin-right: 2em;
  margin-left: 2em;
}

.created-by-container {
  position: relative;
  overflow: hidden;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.created-by-container.splide {
  margin-right: auto;
  margin-left: auto;
}

.created-item {
  padding: 0;
}

.left-arrow {
  position: absolute;
  left: -5.16em;
  top: 14em;
  right: auto;
  bottom: auto;
  z-index: 2;
  width: 2.65em;
  height: 2.65em;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.right-arrow {
  position: absolute;
  left: auto;
  top: 14em;
  right: -5.16em;
  bottom: auto;
  z-index: 2;
  width: 2.65em;
  height: 2.65em;
}

.monkey-container {
  position: relative;
}

.div-block-26 {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.text-block-4 {
  font-size: 1.46em;
  line-height: 1.1;
}

.monkey-image-holder {
  position: absolute;
}

.text-block-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: rotate(31.69deg);
  -ms-transform: rotate(31.69deg);
  transform: rotate(31.69deg);
  font-size: 3.31em;
}

.text-block-6 {
  font-family: Lemonmilk, sans-serif;
  color: #fff;
  font-size: 4.3em;
  font-weight: 700;
}

.link-footer {
  color: #fff;
  font-size: 1.46em;
}

.banana-follow-container {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.top-bg {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
}

.bottom-bg {
  height: 137em;
}

.body-content {
  position: relative;
}

.div-block-27 {
  display: none;
}

.html-embed {
  display: none;
}

.top-part {
  position: relative;
}

.bottom-part {
  position: relative;
}

.bg-bottom {
  display: none !important;
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.top-2 {
  position: relative;
}

.bottom {
  position: relative;
  top: -2%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.div-block-28 {
  position: relative;
}

@media screen and (max-width: 991px) {
  .h2-full {
    font-size: 4em;
  }
  .home-img {
    max-height: 550px;
    width: auto;
  }
  .lead-artist {
    margin: 0;
  }
  .lead-artist-txt {
    width: auto;
  }
  .footer-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 1rem 0;
    font-size: 14px;
    left: 0;
    bottom: 0;
    position: relative;
  }
  .image-wrapper-1 {
    height: 200px;
    right: 0;
    width: 70vw;
  }
  .image-wrapper-2 {
    height: 200px;
    right: 20%;
    width: 70vw;
  }
  .image-wrapper-1 div {
    right: 50%;
  }
  .image-wrapper-1 div img {
    left: 0;
  }
  .image-wrapper-2 div img {
    right: 0;
  }
  .footer-social {
    margin: 2rem 0 1rem 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .footer {
    display: none;
  }
  body {
    background-position: 50% 50%;
    font-size: 1.5vw;
  }
  .paragraph-wrapper {
    width: 100%;
    padding: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .container {
    padding: 5em 0 0 0;
  }

  .container.btf {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .container.roadmap-container {
    padding-top: 5em;
  }

  .container.infected {
    padding-top: 7em;
    padding-right: 4em;
    padding-left: 4em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .container.btf-2 {
    padding-top: 14em;
    padding-right: 4em;
    padding-left: 4em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .container.about-artist-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .container.sneakpeak {
    padding: 7em 4em 5em;
  }

  /* .navbar {
    top: 1.5em;
    right: 1em;
  } */

  .button {
    margin-right: 3em;
  }

  .button.nav-join {
    display: none;
  }

  .heading {
    font-size: 5em;
    white-space: normal;
  }

  .ape-atf {
    top: auto;
    right: 17.78em;
    bottom: 0%;
    width: 30em;
    height: auto;
  }

  .scroll-down {
    left: 8em;
    z-index: 3;
  }

  .mobile-burger-line {
    width: 1.8rem;
    height: 0.18rem;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    background-color: #fff;
  }

  .side-panel {
    left: -12em;
  }

  .side-panel-link-text {
    font-size: 1em;
  }

  .paragraph {
    font-size: 1.46em;
  }

  .paragraph.copyright {
    font-size: 1.2em;
  }

  .roadmap-content-block {
    margin-top: 5em;
  }

  .roadmap-content {
    height: 40em;
    margin-top: 0em;
  }

  .roadmap-content._6 {
    margin-bottom: 0em;
  }

  .changing-words-block {
    width: 15em;
  }

  .div-block-4 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .heading-2 {
    font-size: 4.5em;
  }

  .div-block-10 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-11 {
    max-width: none;
    margin-top: 2em;
    margin-left: 0em;
  }

  .creators-grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .infected-left-side {
    max-width: none;
  }

  .div-block-13 {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .images-overlap-container {
    margin-top: 10em;
  }

  .mobile-appear {
    display: block;
  }

  .mobile-menu {
    overflow: hidden;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .lottie {
    width: 100%;
    height: 100%;
  }

  .div-block-18 {
    width: 25em;
  }

  .div-block-21.no-margin-top-mobile {
    margin-top: 0em;
    margin-bottom: 5em;
  }

  .div-block-19-a {
    margin-left: 0em;
  }

  .div-block-20-a {
    top: -1.8em;
  }

  .about-content {
    margin-left: 0em;
  }

  .div-block-22 {
    max-width: none;
  }

  .created-by-container {
    overflow: visible;
    width: auto;
  }

  .left-arrow {
    display: none;
  }

  .right-arrow {
    display: none;
  }

  .bottom {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 35%;
  }
}

@media screen and (max-width: 767px) {
  .home-img {
    max-height: 500px;
  }
  .container {
    padding: 5em 0 0 0;
  }

  .paragraph.paragraph-heading {
    margin-left: 0;
  }

  .container.btf {
    padding-right: 2em;
    padding-left: 2em;
  }

  .container.infected {
    padding-top: 5em;
    padding-right: 2em;
    padding-left: 2em;
  }

  .container.btf-2 {
    padding-right: 2em;
    padding-bottom: 8em;
    padding-left: 2em;
  }

  .container.sneakpeak {
    padding-right: 2em;
    padding-left: 2em;
  }

  /* .logo-block {
    left: 1.25em;
    top: 2em;
    width: 14em;
    height: auto;
  } */
  .logo-block-footer {
    margin: 1rem 0 0 0;
  }
  /* .navbar {
    right: 0em;
  } */

  .heading {
    font-size: 5.5em;
  }

  .atf-numbers-block {
    margin-bottom: 2em;
  }

  .vertical-block.right-margin {
    margin-right: 2em;
    margin-bottom: 2em;
  }

  .button-bigger.white.mobile-only {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .scroll-down {
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 3%;
    width: 100%;
    padding-right: 1.5em;
    padding-left: 1.5em;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .h2-full.offset {
    font-size: 5em;
  }

  .paragraph.copyright {
    text-align: center;
  }

  .rodmap-paragraphs-block {
    width: 50%;
    margin: 0 0 0 auto;
  }

  .roadmap-content {
    height: 40em;
  }

  .banana-bar {
    margin-right: 1em;
    margin-left: 1em;
  }

  .changing-words-block {
    width: 10em;
  }

  .heading-2 {
    font-size: 3.5em;
  }

  .timer-block {
    grid-column-gap: 1.5em;
  }

  .creators-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .picture-frame {
    width: 100%;
  }

  .div-block-14 {
    margin-bottom: 3em;
  }

  .images-overlap-container {
    font-size: 1.5vw;
  }

  .socials-wrapper {
    margin-bottom: 2em;
  }

  .social-link.edge-no-margin {
    margin-right: 0em;
  }

  .div-block-18 {
    right: 0.4em;
    width: 19em;
  }

  .div-block-20 {
    top: -1em;
    width: 8em;
  }

  .div-block-20-a {
    top: 0em;
    width: 8em;
  }

  .div-block-24 {
    font-size: 1vw;
  }

  .text-block-6 {
    font-size: 3.8em;
  }

  .body {
    font-size: 2vw;
  }

  .link-footer {
    margin-bottom: 1.5em;
  }
}

@media screen and (max-width: 479px) {
  .home-img {
    padding-top: 1rem;
    max-height: 550px;
  }
  .div-block-19 {
    padding-top: 1rem;
    border-width: 3px;
    border-style: solid;
    border-color: rgb(182, 115, 24);
  }
  div.div-block-19.blue-border {
    border-width: 3px;
    border-style: solid;
    border-color: rgb(47, 107, 189);
  }
  body {
    font-size: 2.9vw;
  }
  .spaceShip-bg {
    background-size: 160px 84px;
    margin-top: 4rem;
  }
  .image-wrapper-1 {
    display: none;
  }
  .image-wrapper-2 {
    display: none;
  }

  .container.btf-2 {
    padding-top: 8em;
    padding-bottom: 8em;
    padding-left: 2em !important;
  }

  .container.about-artist-container {
    padding-bottom: 7em;
  }

  .logo-block {
    width: 12em;
    height: 12em;
  }

  .button {
    display: none;
  }

  .atf-header-block {
    padding-bottom: 2.7em;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .heading {
    font-size: 3em;
  }

  .caps-text-light {
    font-size: 1.2em;
  }

  .caps-text-bold {
    font-size: 1.8em;
  }

  .button-contain-atf {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .button-bigger.left-margin {
    margin-top: 2em;
    margin-left: 0em;
  }

  /* .mobile-burger {
    width: 2rem;
    height: 1.5rem;
  } */

  .h2-hollow {
    font-size: 2.3em;
  }

  .h2-hollow.hollow {
    font-size: 1.8em;
  }

  .h2-hollow.bit-smaller {
    font-size: 2.5em;
  }

  .h2-full {
    font-size: 4em;
  }

  .h2-full.offset {
    font-size: 3em;
  }

  .paragraph-wrap-success {
    margin-bottom: 4em;
  }

  .h2-hollow-smaller {
    text-align: center;
  }

  .h2-hollow-smaller.hollow {
    font-size: 1.5em;
  }

  .h3-inside-card {
    font-size: 2em;
  }

  .card-bottom-text {
    height: auto;
    margin-top: 1em;
  }

  .h3-inside-card-text {
    font-size: 1.5em;
    text-align: left;
  }

  .roadmap-content-block {
    margin-top: 2em;
  }

  .roadmap-sticky {
    height: 45em;
  }

  .rodmap-paragraphs-block {
    margin-left: auto;
  }

  .roadmap-content {
    height: 45em;
  }

  .number-tracker {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .changing-numbers-block {
    height: 2em;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .changing-numbers {
    font-size: 1.2em;
  }

  .banana-bar {
    margin-right: 0.5em;
    margin-left: 0.5em;
  }

  .changing-words-block {
    width: 8em;
  }

  .h3-roadmap {
    font-size: 1.5em;
  }

  .h3-roadmap.absolute {
    font-size: 1.2em;
  }

  .heading-2 {
    font-size: 3.6em;
  }

  .heading-2.less-size {
    font-size: 2.5em;
  }

  .div-block-8 {
    margin-right: 3em;
  }

  .unit-text {
    font-size: 1.2em;
  }

  .h2-smaller {
    font-size: 4em;
  }

  .creators-grid.splide__list {
    grid-column-gap: 1em;
    grid-row-gap: 1em;
  }

  .creator-photo {
    width: 17em;
    height: 17em;
  }

  .creator-photo.add-height {
    width: 100%;
  }

  .creator-description {
    font-size: 1.25em;
  }

  .div-block-12 {
    padding-right: 0em;
    padding-left: 0em;
  }

  .div-block-14 {
    margin-bottom: 5em;
  }

  .images-overlap-container {
    font-size: 2.8vw;
  }

  .image-block-1 {
    width: 16em;
    height: 16em;
  }

  .image-block-2 {
    width: 15em;
    height: 15em;
    margin-left: -1em;
  }

  .image-block-3 {
    width: 14em;
    height: 14em;
    margin-top: -4.4em;
    -webkit-transform: rotate(11.76deg);
    -ms-transform: rotate(11.76deg);
    transform: rotate(11.76deg);
  }

  .div-block-20 {
    position: relative;
    top: 0em;
  }

  .div-block-21 {
    margin-top: 3em;
  }

  .div-block-21.offset-up {
    margin-top: -1.5em;
  }

  .div-block-21.no-margin-mobile {
    margin-top: -1em;
  }

  .div-block-20-a {
    position: relative;
  }

  .text-block-6 {
    font-size: 3em;
  }

  .body {
    font-size: 3vw;
  }

  .number-total.total {
    font-size: 1.2em;
  }

  .bottom {
    bottom: 31%;
    max-height: none;
  }
}

@media (max-width: 375px) {
  .nav-link {
    margin-left: 2.3em;
    font-size: 12px;
  }

  .footer-mobile {
    font-size: 12px;
  }
  .home-img {
    max-height: 500px;
  }
}
@media (min-width: 600px) {
  .image-wrapper-1 {
    margin: 2rem 0 0 2rem;
  }
  .navbar {
    margin-right: 2rem;
  }
  .div-block-19 {
    min-width: 50%;
    width: 50%;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .div-block-3 {
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    text-align: left;
    align-items: center;
  }
  .container {
    max-width: 720px;
  }

  .other-artists {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 993px) {
  .timer-content div{
 top: -3.5em;
  }
  .timer-content{
      min-width: 80px;
  }
  .timer-head{
    font-size: 96px;

  }
  .nav-text {
    font-size: 1.2em;
  }
  .div-block-11 {
    text-align: justify;
  }
  div.hero div.caption.mobile {
    display: none;
  }
  div.container.video {
    padding: 0;
    max-width: none;
    width: 100%;
  }
  div.feature.video {
    width: 100%;
  }
  div.hero {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative;
    margin: 5rem 0;
  }
  .hero::after {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background: rgb(0, 0, 0, 0.5);
    z-index: 200;
  }
  div.hero video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 100;
    width: 100%;
    height: 100%;
  }
  div.hero div.caption {
    display: block;
    position: relative;
    z-index: 1000;
    opacity: 1;
  }
  .caption h2 {
    text-align: center;
  }
  .h2-hollow {
    font-size: 3.31em;
  }
  .div-block-26 {
    position: relative;
    display: flex;
    justify-content: center;
    width: 50%;
    /* height: 60%; */
    overflow: hidden;
  }
  .side-panel-wrap {
    display: flex;
    z-index: 999999;
  }
  .logo-block-m {
    display: none;
  }
  .logo-block {
    margin: 0 auto;
    left: auto;
    right: auto;
    top: auto;
    transform: translate(0px, 0px);
    position: relative;
  }
  .navbar {
    margin: 0 auto 0 0;
  }
  .heading {
    text-align: left;
  }
  .mobile-burger {
    display: none !important;
    width: 2rem;
    height: 1.5rem;
  }
  .nav-desktop {
    display: flex;
    align-items: center;
  }
  .div-block-3 {
    width: 100%;
    margin: 0;
    flex-direction: row;
    align-items: flex-start;
  }
  .image-wrapper-1 {
    margin: 0rem 0 0 5rem;
  }
  .container {
    padding: 5em 0 0 0;
    max-width: 960px;
  }
  header {
    margin: 0 auto;
    width: 80%;
    align-items: center;
  }
  .feature {
    width: 80%;
    margin: 0 auto;
    align-items: center;
  }
  .roadmap-wrapper {
    width: 80%;
    margin: 0 auto;
  }
  .div-block-10 {
    width: 80%;
    margin: 0 auto;
  }
  .creators-wrapper {
    width: 80%;
    margin: 0 auto;
  }
  .faq-wrapper {
    width: 80%;
    margin: 0 auto;
  }
  .other-artists {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .home-img {
    max-height: 800px;
  }
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1440px) {
  .container {
    max-width: 1312px;
  }
  div.image-wrapper-1 {
    margin-top: 15rem;
  }
}
@media (min-width: 2000px) {
  .home-img {
    max-height: 1000px;
  }
  .container {
    max-width: 1512px;
  }

  .heading {
    font-size: 6.28em;
  }
  header {
    width: 100%;
  }
  .feature {
    width: 100%;
  }
  .roadmap-wrapper {
    width: 100%;
  }
  .div-block-10 {
    width: 100%;
  }
  .creators-wrapper {
    width: 100%;
  }
  .faq-wrapper {
    width: 100%;
  }
  div.image-wrapper-1 {
    height: 600px;
    margin-top: 30rem;
  }
}
